import { WORKFLOW_1_STEP_NAME } from '../enums/workflow-1-step-name.enum';

export const ONBOARDING_CREATION_STEP_CONFIG = {
  [WORKFLOW_1_STEP_NAME.Offer]: {
    title: 'Offre & devis',
  },
  [WORKFLOW_1_STEP_NAME.LegalMeeting]: {
    title: 'RDV juridique',
  },
  [WORKFLOW_1_STEP_NAME.Launch]: {
    title: 'Lancement',
  },
  [WORKFLOW_1_STEP_NAME.StatusWritings]: {
    title: 'Statuts',
  },
} as const;
