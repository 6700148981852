import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { LegalCreationTotalPricingDto } from '../dto/legal-creation-total-pricing.dto';

@Injectable({
  providedIn: 'root',
})
export class QuoteHttpService {
  constructor(private readonly http: HttpClient) {}

  getLegalCreationPricing(companyId: number): Observable<LegalCreationTotalPricingDto> {
    return this.http.get<LegalCreationTotalPricingDto>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/quotes/legal-creation-pricing`,
    );
  }
}
