import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService, URL } from '@dougs/core/routing';

@Injectable({
  providedIn: 'root',
})
export class CanAccessToOnboardingCreationGuard {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly routingService: RoutingService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.companyStateService.activeCompanyIdChanged$.pipe(
      map((activeCompany) => {
        if (activeCompany.isCreated) {
          return this.routingService.createUrl([URL.TODO]);
        }

        return true;
      }),
    );
  }
}
