import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppointmentType } from '@dougs/accounting/onboarding/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';

import { CalendlyMockHttpService } from '../http/calendly-mock.http';

type CalendlyMockState = Record<string, never>;

@Injectable({
  providedIn: 'root',
})
export class CalendlyMockStateService extends StateService<CalendlyMockState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly calendlyMockHttpService: CalendlyMockHttpService,
  ) {
    super();
  }

  async mockAppointmentCreatedEvent(userId: number, appointmentType: AppointmentType): Promise<void> {
    try {
      await lastValueFrom(this.calendlyMockHttpService.mockAppointmentCreatedEvent(userId, appointmentType));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async mockAppointmentCanceledEvent(userId: number, appointmentType: AppointmentType): Promise<void> {
    try {
      await lastValueFrom(this.calendlyMockHttpService.mockAppointmentCanceledEvent(userId, appointmentType));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
