export type OnboardingCreationFormHelp<T, D> = {
  type: T;
  data: D;
};

export type OnboardingCreationFormHelpText = OnboardingCreationFormHelp<
  typeof OnboardingCreationFormHelpTypes.TEXT,
  {
    title: string;
    text: string;
  }
>;

export type OnboardingCreationFormHelpArticle = OnboardingCreationFormHelp<
  typeof OnboardingCreationFormHelpTypes.ARTICLE,
  {
    title: string;
    url: string;
    videoId?: string;
  }
>;

export enum OnboardingCreationFormHelpTypes {
  TEXT = 'TEXT',
  ARTICLE = 'ARTICLE',
}
