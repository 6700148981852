import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class OnboardingCreationMockHttpService {
  constructor(private readonly http: HttpClient) {}

  mockLegalAppointmentDone(companyId: number): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/test/companies/${companyId}/onboarding-creation/mock-legal-appointment-done`,
      null,
    );
  }

  mockLegalCreationQuote(companyId: number): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/test/companies/${companyId}/onboarding-creation/mock-legal-creation-quote`,
      null,
    );
  }

  mockLegalCreationFinalization(companyId: number): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/test/companies/${companyId}/onboarding-creation/mock-legal-creation-finalization`,
      null,
    );
  }

  mockLegalCreationTransitToAccounting(companyId: number): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/test/companies/${companyId}/onboarding-creation/mock-legal-creation-transit-to-accounting`,
      null,
    );
  }
}
